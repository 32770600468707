
@font-face {
  font-family: 'ABCWhyte';
  src: url('../public/fonts/ABCWhyte-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCWhyte';
  src: url('../public/fonts/ABCWhyte-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'ABCWhyte', Helvetica,"Sans-serif" !important;
}

body {
  margin: 0;
  font-family: 'ABCWhyte', Helvetica,"Sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}


h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3.5rem;
  color: #000;
}

h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: #000;
}

h3 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  color: #000;
}

h4 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  color: #000;
}

h5 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #000;
}

h6 {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: #000;
}


.item-added {
  font-size: 13px;
    color: green;
    margin-top: 6px;
    font-weight: 600;
}


.published {
  font-size: 12px;
  background: #04d590;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
}

.unpublished {
  font-size: 12px;
  background: red;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 600;
  color: white;
}

.onholdStatus {
  font-size: 12px;
  background: #f90;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
}

.pendingStatus {
  font-size: 12px;
  background: #00BCD4;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
}

input:focus-visible {
  box-shadow: 0 0 0 1px #e98429 !important;
  border-color: #e98429 !important;
}

textarea:focus-visible {
  box-shadow: 0 0 0 1px #e98429 !important;
  border-color: #e98429 !important;
}

select:focus-visible {
  box-shadow: 0 0 0 1px #e98429 !important;
  border-color: #e98429 !important;
}

.sub_headings {
  position: relative;
  bottom: 10px;
  color: gray;
}
.explore{
  position: relative;
  
}

.mysubnavbar{
  /* display: none; */
  transition: all 200ms ease-in-out;
}

.explore::before {
  
content: "";
position: absolute;
top: 100%;
left: 0px;
width: 0;
height: 3px;
background-color:rgb(255, 123, 0);
transition: all .2s linear;
}
.explore:hover::before {
  width: 100%; 
}
.subCategory{
  position: relative;

}
.subCategory:before {
  
content: "";
position: absolute;
top: 100%;
left: 0px;
height: 3px;
width: 0;
background-color:rgb(243, 138, 52);
transition: all 0.3s linear;

}
.subCategory:hover:before {
  width: 50%;
}

/* .navbar:hover .mysubnavbar{
  height: 430px;
} */
.navbar:hover{
background-color:"white"
}

.absolute-box{
  overflow: hidden;
}

.relative-box:hover .absolute-box {
  border-radius: 10px;
  background-color: rgba(255, 111, 0, 0.2); /* Change to desired color */
}
.absolute-box:hover .view-campaign-btn{
  top: 250px;
}
.view-campaign-btn{
  position: absolute;
  top: 320px;
  transition: all 200ms linear;
}

.media-box {
  width: 110%;
  position: relative;
  left: -20px;
  overflow: hidden !important;
}

.media-box div {
  border-radius: 0px !important;
}